.App {
  font-family: sans-serif;
  text-align: center;
}

body {
  margin: 0;
  height: 100vh;
  
  background-image: linear-gradient(-206deg, #835eff 0%, #ff008d 100%);
}

.lamp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100vh;
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  z-index: -1;
}

.lava {
  filter: url("#goo");
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.blob {
  border-radius: 50%;
  background-image: linear-gradient(-206deg, #ff9298 0%, #ff008d 100%);
  /* background-image: linear-gradient(-206deg, #ff9298 0%, #e4008e 100%); */
  position: absolute;
}

.blob.top {
  border-radius: 50%;
  width: 100%;
  height: 4%;
  top: -3%;
  left: 0;
}
.blob.bottom {
  border-radius: 50%;
  width: 100%;
  height: 4.5%;
  bottom: -3%;
  left: 0;
}
.blob:nth-child(1) {
  width: 200px;
  height: 200px;
  left: 35%;
  bottom: -15%;

  animation: wobble 4s ease-in-out alternate infinite,
    blob-one ease-in-out 18s infinite;
}
.blob:nth-child(2) {
  width: 330px;
  height: 330px;
  right: 24%;
  bottom: -65%;

  animation: wobble 5s ease-in-out alternate infinite,
    blob-two ease-in-out 22s infinite;
}
.blob:nth-child(3) {
  width: 150px;
  height: 150px;
  bottom: -15%;
  left: 34%;

  animation: wobble 6s ease-in-out alternate infinite,
    blob-three ease-in-out 16s infinite;
}
.blob:nth-child(4) {
  width: 235px;
  height: 235px;
  bottom: -19%;
  left: 30%;

  animation: wobble 8s ease-in-out alternate infinite,
    blob-four ease-in-out 16s infinite;
}
.blob:nth-child(5) {
  width: 55px;
  height: 55px;
  bottom: -25%;
  left: 34%;

  animation: wobble 9s ease-in-out alternate infinite,
    blob-five ease-in-out 32s infinite;
}
.blob:nth-child(6) {
  width: 35px;
  height: 35px;
  bottom: -25%;
  right: 34%;

  animation: wobble 10s ease-in-out alternate infinite,
    blob-six ease-in-out 12s infinite;
}
.blob:nth-child(7) {
  width: 435px;
  height: 435px;
  bottom: -85%;
  right: 40%;

  animation: wobble 11s ease-in-out alternate infinite,
    blob-seven ease-in-out 32s infinite;
}
@keyframes blob-one {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-600%);
  }
}

@keyframes blob-two {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-420%);
  }
}

@keyframes blob-three {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-305%);
  }
}
@keyframes blob-four {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-465%);
  }
}
@keyframes blob-five {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-six {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-700%);
  }
}
@keyframes blob-seven {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-300%);
  }
}

@keyframes wobble {
  50% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  }
  100% {
    border-radius: 38% 52% 75% 36% / 50% 40% 50% 60%;
  }
}

#urlinput {
  width: 50vw;
  height: 5vh;
  opacity: .5;
  border-radius: 2px;
  border: none;
}

#pasteclipbutton {
  padding: 1vw;
  width: 50vw;
  height: 5vh;
  background-color: lightblue;
  color: black;
  text-decoration: none;
  border: none;
  margin: 1vh;
  opacity: .5;
  border-radius: 2px;
}

#searchbutton {
  padding: 1vw;
  width: 50vw;
  height: 5vh;
  background-color: lightgreen;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border: none;
  margin: 1vh;
  opacity: .5;
  -webkit-border-radius: 2px;
}

#urlform {
  /* width: 80vw;
  height: 5vh;
  padding: 1vw;
  /* position: absolute; */
  /* top:-50%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; */ 
}

#pasteclipbutton:hover {
  opacity: 1;
}

#searchbutton:hover {
  opacity: 1;
}

#urlinput:hover {
  opacity: 1;
}


#response {
  padding-top: 5%;
  width: 20vw;
  padding-left: 37.5vw;
}

#albumcover {
  width: 100%;
  height: auto;
}

#songartist {
  width: 100%;
  text-align: left;
}

.reslinks {
  width: 50%;
}

.resicon {
  width: 80%;
  height: auto;
  max-height: 100px;
}

#sharediv {
  display: flex;
  justify-content: center;
}

#sharebutton {
  padding: 1vw;
  width: 50%;
  height: 5vh;
  background-color: lightblue;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border: none;
  margin-right: 4px;
  opacity: .5;
  border-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
}

#sharebutton:hover {
  opacity: 1;
}

#textbutton {
  padding: 1vw;
  width: 50%;
  height: 5vh;
  background-color: lightgreen;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border: none;
  margin-left: 4px;
  opacity: .5;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  cursor: pointer;
}

#textbutton:hover {
  opacity: 1;
}

#links {
  display: flex;
  justify-content: space-between;
}

#backlinkbox {
  width: 100%;
  text-align: left;
}

#backlink {
  padding: 1vw;
  width: 50vw;
  height: 5vh;
  background-color: lightgrey;
  color: black;
  text-decoration: none;
  font-weight: bold;
  border: none;
  opacity: .5;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 0;
  margin-bottom: 2vh;
}

#backlink:hover {
  opacity: 1;
}

#loader-container {
  padding: 1vw;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  display: none;
}

#load-spinner {
  /* border: 16px solid #f3f3f3; */
  border-top: 16px solid #81007f;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  animation: spin 2s linear infinite;

  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;  

}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

#titleinfo {
  color: white;
  width: 50vw;
  text-align: left;
}

#initialcontents {
  width: 80vw;
  height: 5vh;
  padding: 1vw;
  position: absolute;
  top:-25%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  display: flex; 
  justify-content: center; 
  align-items: center; 
}

#sharediv2 {
  display: flex;
  justify-content: center;
}

.twitter-share-button {
  width: 100%;
  text-decoration: none;
  color: white;
}

.twitterbutton {
  padding: 1vw;
  background-color: black;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border: none;
  opacity: .5;
  border-radius: 5px;
  cursor: pointer;
}

.twitterbutton:hover {
  opacity: 1;
}

@media only screen and (max-width: 500px) {
  #response {
    padding-top: 10%;
    width: 50vw;
    height: auto;
    padding-left: 25vw;
  }
  
  .App {
    height: 60vh;
    width: 60vw;
  }

  #backlink {
    padding: 2vw;
    margin-bottom: 0;
  }
} 